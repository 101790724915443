<template>
    <div class="any-page">
        <div class="any-page-header">
            <slot name="page-header">
                <slot name="main-title">
                    <h1 class="main-title"><slot name="main-title-caption"></slot></h1>
                </slot>
                <slot name="secondary-title">
                    <h2 class="secondary-title-"><slot name="secondary-title-caption"></slot></h2>
                </slot>
            </slot>
        </div>
        <div class="any-page-intro">
            <slot name="page-intro"></slot>
        </div>
        <div class="any-page-content">
            <slot></slot>
        </div>
        <div class="any-page-footer">
            <slot name="footer"></slot>
        </div>
        <slot name="dialogs-placeholder"></slot>
    </div>
</template>

<script>
export default { }
</script>
